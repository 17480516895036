@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora/Sora-VariableFont_wght.ttf");
}

:root {
  --primaryColor: #e84143;
  --primaryColor2: #0a2244;
}

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Sora", "Courier New", Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primaryColor2);
  scroll-behavior: smooth;
  color: #fff;
  font-size: calc(8px + 0.390625vw);
}

a {
  color: inherit;
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none;
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block !important;
}

.text-align-center {
  text-align: center;
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

.form-control {
  outline: none;
  border: 1px solid var(--primaryColor);
  border-radius: 8px;
  padding: 1.2rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  width: 100%;
  color: var(--primaryColor);
  background: transparent;
}

.form-control:focus {
  outline: none;
}

input:disabled {
  background-color: #ccc;
  color: #808080;
  cursor: not-allowed;
}

/* cuustom input start */
.material-textfield {
  position: relative;
}

label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: inherit;
  color: #fff;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  background-color: var(--primaryColor2);
}

.infoLabelContainer {
  margin-top: 2%;
  margin-bottom: 2.5rem;
  margin-left: 3%;
}

.infoLabel {
  text-align: left;
  white-space: pre-line;
  font-weight: 500;
  font-size: 0.7rem;
}

input,
select {
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 1rem 0.7rem;
  font-weight: 400;
  height: auto;
  width: 100%;
  color: #fff;
  transition: 0.1s ease-out;
}
input:focus,
select:focus {
  border-color: #fff;
}
input:focus + label,
select:focus + label {
  color: #fff;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
input:not(:placeholder-shown) + label,
select:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

/* custom input end */

.cursor-pointer {
  cursor: pointer;
}

.btn {
  margin-top: 1rem;
  border-radius: 7px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  background-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  color: #fff;
  display: flex;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-width: 7rem;
  transition: all 0.3s;
}

.btn:hover:not([disabled]) {
  background-color: #fff;
  color: var(--primaryColor);
  border-color: var(--primaryColor);
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

button:disabled {
  background-color: #808080;
  cursor: not-allowed;
}

/* hide updown arrow of type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primaryColor2) inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
input {
  /* -webkit-background-clip: text !important; */
}

.modalOverlay,
.modalOverlayWithTint {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.modalOverlayWithTint {
  background: rgba(0, 0, 0, 0.4);
}

.modalOverlayWithBlur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(27, 50, 87, 0.6);
  backdrop-filter: blur(32px);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.ant-popover-inner {
  margin-right: 2rem;
}

.ant-tabs-ink-bar {
  height: 4px !important;
  border-radius: 4px !important;
  background-color: var(--primaryColor) !important;
}

.ant-tabs-tab-btn {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--primaryColor) !important;
}

:where(.css-dev-only-do-not-override-14i19y2).ant-progress .ant-progress-inner {
  background-color: rgba(0, 0, 0, 0.6);
}

/* toast container class */
.Toastify__toast-theme--light {
  background-color: var(--primaryColor);
  /* color: #fff; */
}

/* .Toastify__toast-icon > svg {
  --toastify-icon-color-success: var(--primaryColor);
} */

.Toastify__close-button {
  /* color: #fff; */
  opacity: 0.7;
}

/* error */
.error {
  color: red;
  padding-bottom: 1rem;
}

.bgBlur {
  position: fixed;
  top: 0;
  height: 100vh;
  height: 100svh;
  width: 100%;
  pointer-events: none;
}
.bgBlur::before,
.bgBlur::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 353px;
  background: var(--primaryColor);
  filter: blur(250px);
}

.bgBlur::before {
  top: -30%;
  right: -80%;
}
.bgBlur::after {
  bottom: -60%;
  left: -80%;
}

.highlightStrokes {
  position: fixed;
  bottom: 2px;
  right: -25%;
  max-height: 13rem;
  z-index: 3;
}
.highlightStrokesCapture {
  bottom: -2rem;
}

@media screen and (max-width: 1024px) {
  .bgBlur::after {
    bottom: -190%;
  }
  .ant-tabs-tab-btn {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .ant-tabs-tab-btn {
    font-size: 2rem;
  }
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: none !important;
  }

  .highlightStrokes {
    bottom: 89%;
    max-height: 5rem;
  }
  .highlightStrokesCapture {
    bottom: 88%;
  }

  .bgBlur::before {
    top: 35%;
  }
  .bgBlur::after {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .form-control {
    padding: 1.1rem 1.5rem;
  }
}

@media only screen and (min--moz-device-pixel-ratio: 1.25),
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-device-pixel-ratio: 1.25) {
  html,
  body {
    font-size: calc(8px + 0.390625vw);
  }
}

@media only screen and (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  body,
  html {
    font-size: calc(8px + 0.390625vw);
  }
}
