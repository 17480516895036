.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  position: relative;
  z-index: 1;
  justify-content: center;

  .heading {
    h1 {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }
    h4 {
      text-align: center;
      font-size: 1rem;
      font-weight: 200;
    }
    margin-bottom: 2.5rem;
  }

  .userImage {
    border: 2px solid var(--primaryColor);
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    padding: 2rem;
    img {
      height: 100%;
      width: 100%;
      border-radius: inherit;
      object-fit: cover;
      object-position: center;
    }
  }

  .formContainer {
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    .inputWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // flex-wrap: wrap;
      // gap: 3rem;
    }
    .BottomWrapper {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-self: center;
      align-self: center;
      flex-direction: column;
      gap: 1rem;
      .termsText {
        width: 100%;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        text-align: left;
        // margin-left: 1rem;
        p {
          width: 80%;
        }
        a {
          color: var(--primaryColor);
        }
      }
    }

    .input-group {
      min-width: 23rem;

      select {
        margin-top: 1rem;
      }
      input {
        border-radius: 1rem;
        background-color: var(--primaryColor2) !important;
      }
      label {
        font-weight: 900;
      }
    }
  }

  // Checkbox.module.scss
  .checkbox {
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid grey; // Border color (adjust as needed)
    width: 20px;
    height: 20px;
    // display: inline-block;
    // cursor: pointer;
    border-radius: 3px;
    padding: 0;
    margin-right: 1rem;

    &:checked {
      background-color: transparent;
      border-color: var(--primaryColor); // Tick color
    }

    &:checked::after {
      content: "";
      display: block;
      width: 8px;
      height: 13px;
      border: solid var(--primaryColor);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
      // top: -0.75rem;
      left: 0.35rem;
      // margin-left: 5px;
      // margin-top: 2px;
    }
  }
}

.dp {
  option {
    text-transform: capitalize;
  }

  & > :first-child {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    gap: 3rem;
    .heading {
      h1 {
        font-size: 2.5rem;
      }
      h4 {
        font-size: 1.4rem;
      }
    }
    .userImage {
      height: 24rem;
      width: 24rem;
    }
    .formContainer {
      max-width: 70%;
      .BottomWrapper {
      }
      .input-group {
        min-width: 28rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    gap: 2rem;
    .heading {
      // margin-left: 1.5%;
      align-self: center;

      h1,
      h4 {
        text-align: center;
        width: 100%;
      }
    }
    .userImage {
      height: 30rem;
      width: 30rem;
    }
    .formContainer {
      gap: 1rem;
      max-width: 85%;
      .BottomWrapper {
        .secondTextBox {
          // width: 4.5rem;
        }
      }
      .input-group {
        min-width: 80vw;
      }
    }
  }
}
