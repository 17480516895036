.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 9;
  padding: 1rem 2.5rem;
  height: 5rem;
  z-index: 2;

  .brandContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
    img {
      max-width: 7rem;
      object-fit: contain;
    }

    .breadcrumb {
      display: flex;
      gap: 1rem;
      font-size: 1.2rem;

      * {
        color: var(--primaryColor2);
      }

      .crumb {
        font-weight: 500;
      }
      .crumb.active {
        font-weight: 900;
      }
    }
    .breadcrumbContainer {
      display: block;
    }
  }
}
.dashboardBtn {
  margin-top: 0;
  font-size: 1rem;
  height: 3rem;
}
.right {
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  }
}
.avatar {
  font-weight: 700;
}

.hamburgerContainer {
  display: none;
  span {
    display: none;
  }
}

.hamburger {
  display: none;
  font-size: 2rem;
}
.profileContainer {
  min-width: 8rem;
  border-radius: 1rem;
  padding: 0.5rem;

  .userDetail {
    .name {
      text-transform: capitalize;
      font-size: 1.1rem;
      font-weight: 700;
      color: var(--primaryColor2);
    }
    .hq {
      color: var(--primaryColor2);
    }
  }

  .logoutBtn {
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    height: 6rem;
    .brandContainer {
      img {
        max-width: 11rem;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .wrapper {
    background-color: var(--primaryColor2);
    .brandContainer {
      .breadcrumbContainer {
        display: none;
      }
    }
  }
  .hamburger {
    display: block;
  }
  .hamburgerContainer {
    display: block;
    .crumb {
      font-weight: 500;
    }
    .crumb.active {
      font-weight: 900;
    }
    span {
      display: none;
    }
  }
}
