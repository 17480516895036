.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8vw;
  height: 100vh;
  height: 100svh;
  background-image: url("../../assets/images/loginbg.jpg");
  background-size: cover;

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7rem;
    min-width: 30%;
    margin-top: -10%;

    .logo {
      max-width: 15rem;
    }

    .heart {
      max-width: 57%;
    }

    .title {
      display: none;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    gap: 2rem;

    h1 {
      font-size: 4rem;
      line-height: 0.9;
      text-align: center;
    }

    h2 {
      font-weight: 500;
      font-size: 1.8rem;
      width: 80%;
      margin-bottom: 2rem;
      text-align: center;
    }
    .myBtn{
      // width: 5rem;
      // margin: 0 auto;
      // position: relative;
      // right: 4rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    gap: 2rem;
    flex-direction: column;
    background-position: 70%;

    .left {
      gap: 2rem;
      min-width: 100%;

      .logo {
        max-width: 18rem;
      }

      .heart {
        max-width: 50%;
      }

      .title {
        display: block;
        font-size: 5rem;
        font-weight: 700;
      }
    }

    .right {
      width: 100%;
      gap: 2rem;

      h1 {
        display: none;
      }

      h2 {
        text-align: center;
      }

      h2 {
        font-size: 3rem;
        width: 60%;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    gap: 0;
    background-position: 58%;

    .left {
      margin-top: -10%;
      min-width: 75%;
    }

    .right {
      width: 100%;

      h2 {
        width: 60%;
        margin-bottom: 2rem;
        font-size: 2.4rem;
        position: relative;
        top: 1rem;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .container {
    justify-content: flex-start;
    .left {
      margin-top: 10%;
      gap: 3rem;

      .logo {
        max-width: 16rem;
      }

      .heart {
        max-width: 50%;
      }

      .title {
        font-size: 3rem;
      }
    }

    .right {
      h2 {
        text-align: center;
      }

      h2 {
        width: 80%;
        margin-bottom: 0;
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .container {
    .left {
    }
    .right {
      gap: 1rem;
    }
  }
}

@media screen and (max-width: 1370px) and (orientation: "landscape") {
  .container {
    flex-direction: row;

    .left {
      gap: 5rem;
      margin-top: -10%;
      min-width: 40%;

      .logo {
        max-width: 14rem;
      }

      .heart {
        max-width: 50%;
      }
    }

    .right {
      h1 {
        display: block;
      }

      h2 {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: "landscape") {
  .container {
    flex-direction: row;
    gap: 2vw;

    .left {
      gap: 3rem;
      margin-top: -3%;
      min-width: 50%;

      .logo {
        max-width: 13rem;
      }

      .title {
        display: none;
      }
    }

    .right {
      h1 {
        font-size: 3.2rem;
      }

      h2 {
        width: 80%;
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 700px) and (orientation: "landscape") {
  .container {
    gap: 2vw;

    .left {
      gap: 3rem;
      margin-top: -5%;

      .title {
        display: none;
      }
    }

    .right {
      h2 {
        width: 90%;
        font-size: 1.6rem;
      }
    }
  }
}
